import {Deferred, EOP_ERRORS, schedule} from "../../../common/utils/promises";
import {VideoTracking, VideoTrackingFactory} from "../../../tracking/videoTracking";
import {LoadAfterConfirmationEvents} from "../../../page/elements/loadAfterConfirmation";
import {IntersectionObserverFactory, onceIntersected, ResizeObserverFactory} from "../../../common/observation";
import {resolve} from "../../../container";
import {YoutubeIframeApiLoader, YoutubeService, YoutubeVideoProgress} from "./youtube";
import {UnLitElement} from "../../../common/elements";
import {property} from "lit/decorators.js";

const YOUTUBE_NOCOOKIE_HOST = "https://www.youtube-nocookie.com";

export enum PlayerState {
    ENDED = 0,
    PLAYING = 1,
    PAUSED = 2,
}

export class EopYoutubePlayer extends UnLitElement {

    @property({attribute: "aspect-ratio"})
    public aspectRatio: string;

    private player: YT.Player;
    private intersectionObserver: IntersectionObserver;
    private intersectionDeferred: Deferred<void>;
    private videoTracking: VideoTracking;

    private youtubeIframeApiLoader: YoutubeIframeApiLoader;
    private videoTrackingFactory: VideoTrackingFactory;
    private loadAfterConfirmationEvents: LoadAfterConfirmationEvents;
    private youtubeService: YoutubeService;
    private intersectionObserverFactory: IntersectionObserverFactory;
    private resizeObserverFactory: ResizeObserverFactory;

    public constructor() {
        super();
        this.youtubeIframeApiLoader = resolve(YoutubeIframeApiLoader);
        this.videoTrackingFactory = resolve(VideoTrackingFactory);
        this.loadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents);
        this.youtubeService = resolve(YoutubeService);
        this.intersectionObserverFactory = resolve(IntersectionObserverFactory);
        this.resizeObserverFactory = resolve(ResizeObserverFactory);

        this.intersectionDeferred = new Deferred<void>();
    }

    public connectedCallback(): void {
        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(
                () => this.intersectionDeferred.resolve()),
            {threshold: 0, rootMargin: "100% 0% 100% 0%"}
        );
        this.intersectionObserver.observe(this);

        this.loadAfterConfirmationEvents.onConfirmation("youtube", () => {

            return schedule(this.intersectionDeferred.promise.then(async () => {
                await this.youtubeIframeApiLoader.run();
                let dimensions = this.calculateDimensions();
                this.player = new YT.Player(this.querySelector(".video-placeholder")!, {
                    host: YOUTUBE_NOCOOKIE_HOST,
                    videoId: this.getAttribute("video-id") ?? "",
                    events: {
                        onStateChange: (ev) => this.onStateChange(ev)
                    },
                    width: dimensions.width,
                    height: dimensions.height,
                    playerVars: {
                        iv_load_policy: 3,
                        modestbranding: 1,
                        rel: 0,
                        showinfo: 0,
                        origin: this.getAttribute("origin-stage") ?? undefined
                    }
                });
                this.youtubeService.getVideoInfo(this.getAttribute("video-id") ?? "")
                    .then(info => {
                        this.videoTracking = this.videoTrackingFactory.create(info.title ?? "", new YoutubeVideoProgress(this.player));
                    })
                    .catch(EOP_ERRORS);
                this.resizeObserverFactory.create(() => {
                    dimensions = this.calculateDimensions();
                    this.player.setSize(dimensions.width, dimensions.height);
                }).observe(document.body);
            }).catch(EOP_ERRORS)).as("iframe-api-loaded");
        });


    }

    private calculateDimensions(): { height: number, width: number } {
        let height;
        let width = this.findNearestParentWithWidth(this);
        if (this.aspectRatio === "vertical") {
            const viewportHeight = window.innerHeight;
            const ratio = viewportHeight / width;
            if (ratio > 1.77) {
                height = width * 1.77;
            } else {
                height = viewportHeight;
                width = viewportHeight / 1.77;
            }
            return {height: height, width: width};
        } else {
            height = width / 1.77;
            return {height: height, width: width};
        }
    }

    private findNearestParentWithWidth(element: HTMLElement): number {
        return element.parentElement?.classList.contains("youtube-container") ? element.parentElement.offsetWidth : this.findNearestParentWithWidth(element.parentElement!);
    }

    public disconnectedCallback(): void {
        if (this.videoTracking) {
            this.videoTracking.disconnect();
        }
        this.intersectionObserver.disconnect();
    }

    private onStateChange(event: YT.OnStateChangeEvent): void {
        const playerState = event.data;
        if (playerState === PlayerState.PLAYING) {
            this.videoTracking.playing();
        }
        if (playerState === PlayerState.PAUSED) {
            this.videoTracking.pause();
        }
        if (playerState === PlayerState.ENDED) {
            this.videoTracking.ended();
        }
    };

}