import {Load} from "../../common/load";
import type {TrackingListener} from "../listener";
import type {ElementViewTrackingData, InputTrackingData, InteractionTrackingData, VideoEventTrackingData, ViewTrackingData} from "../trackingData";
import {Tracking} from "../tracking";
import {isArray} from "../../bootstrap/common/arrays";
import {isObject} from "../../common/utils/objects";
import {isDefined} from "../../common/utils/basics";
import {GLOBAL} from "../../common/globals";
import {Nav} from "../../common/nav";
import {autoRegister, resolve} from "../../container";
import {Configuration} from "../../common/config";
import {elementFrom} from "../../common/utils/html";

export type GoogleTagManagerTrackingData = {
    pageName?: string;
    event?: string;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
};

export type GoogleTagManagerWindow = Window & {
    dataLayer: any;
};

@autoRegister()
export class NativeGoogleTagManager {
    private window = GLOBAL.window() as GoogleTagManagerWindow;
    private head = GLOBAL.headElement();

    private GOOGLE_TAG_MANAGER_ID: string | undefined;

    public constructor(
        private load: Load = resolve(Load),
        configuration: Configuration = resolve(Configuration)) {
        this.GOOGLE_TAG_MANAGER_ID = configuration.get("GOOGLE_TAG_MANAGER_ID");
    }

    public init(): void {
        this.window.dataLayer = this.window.dataLayer ?? [];
        this.window.dataLayer.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime()
        });
        const anchorScript = elementFrom(`<script id="anchor-for-google-tag-manager"></script>`);
        this.head.appendChild(anchorScript);

        if (isDefined(this.GOOGLE_TAG_MANAGER_ID)) {
            void this.load.script(`https://www.googletagmanager.com/gtm.js?id=${this.GOOGLE_TAG_MANAGER_ID}`);
        }
    }

    public push(data: GoogleTagManagerTrackingData): void {
        this.window.dataLayer.push(data);
    }
}

@autoRegister()
export class LazyNativeGoogleTagManager {

    public constructor(private nativeGoogleTagManager: NativeGoogleTagManager = resolve(NativeGoogleTagManager)) {
    }

    public init(): void {
        this.nativeGoogleTagManager.init();
    }

    public push(data: GoogleTagManagerTrackingData): void {
        this.nativeGoogleTagManager.push(data);
    }

    public async pushAsync(data: GoogleTagManagerTrackingData): Promise<void> {
        this.push(data);
    }
}

@autoRegister()
export class GoogleTagManagerTracking {
    public constructor(private lazyNativeGoogleTagManager: LazyNativeGoogleTagManager = resolve(LazyNativeGoogleTagManager)) {
    }

    public sendPageView(pagePath: string): void {
        this.lazyNativeGoogleTagManager.pushAsync({
            event: "page",
            pageName: pagePath
        });
    }

    public sendEvent(eventCategory: string, eventAction: string, eventLabel?: string): void {
        this.lazyNativeGoogleTagManager.pushAsync({
            event: "event",
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel
        });
    }

    // explicitly not async, since otherwise the tracking event could be lost during page load
    public sendLinkEvent(eventCategory: string, eventAction: string, eventLabel?: string): void {
        this.lazyNativeGoogleTagManager.push({
            event: "event",
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel
        });
    }

    public setPagePath(pagePath: string): void {
        this.lazyNativeGoogleTagManager.pushAsync({
            pageName: pagePath
        });
    }

    public init(): void {
        this.lazyNativeGoogleTagManager.init();
    }
}

export class GoogleTagManagerTrackingListener implements TrackingListener {

    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking, private nav: Nav = resolve(Nav)) {
    }

    public elementView(data: ElementViewTrackingData): void {
        const viewedElement = data.viewedElement;
        if (isObject(viewedElement)) {
            this.googleTagManagerTracking.sendEvent("element", viewedElement.type, viewedElement.name);
        }
    }

    public view(data: ViewTrackingData): void {
        this.sendPageView(data);
    }

    public errorView(data: ViewTrackingData): void {
        this.sendPageView(data);
    }

    public initialView(): void {
        this.googleTagManagerTracking.init();
    }

    public input(data: InputTrackingData): void {
        if (isDefined(data.controlState) && isDefined(data.controlId)) {
            this.googleTagManagerTracking.sendEvent("form", data.controlId, data.controlState);
        }
    }

    public interaction(data: InteractionTrackingData): void {
        this.googleTagManagerTracking.sendLinkEvent(data.eventSection, data.eventElement, data.eventName);
    }

    public videoEvent(data: VideoEventTrackingData): void {
        if (isDefined(data.videoEvent)) {
            this.googleTagManagerTracking.sendEvent("content.video", data.videoEvent, data.videoTitle);
        }
    }

    private sendPageView(data: ViewTrackingData): void {
        this.googleTagManagerTracking.sendPageView(this.computeVirtualUrl(data, this.nav.pathname()));
    }

    private computeVirtualUrl(data: ViewTrackingData, path: string): string {
        let resultPath = path;

        const nameFragments = data.nameFragments;
        if (isArray<string>(nameFragments)) {
            if (path.endsWith("/")) {
                resultPath = path.slice(0, -1);
            }
            nameFragments.forEach(element => resultPath = resultPath + "/" + element);
        }
        return resultPath;
    }
}

@autoRegister()
export class GoogleTagManagerTrackingListenerFactory {
    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking = resolve(GoogleTagManagerTracking)) {
    }

    public createListener(): GoogleTagManagerTrackingListener {
        return new GoogleTagManagerTrackingListener(this.googleTagManagerTracking);
    }
}

export class EopGoogleTagManagerTracking extends HTMLElement {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private googleTagManagerTrackingListenerFactory: GoogleTagManagerTrackingListenerFactory = resolve(GoogleTagManagerTrackingListenerFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const googleTagManagerTrackingListener = this.googleTagManagerTrackingListenerFactory.createListener();
        this.tracking.registerUnnamedListener(googleTagManagerTrackingListener);
    }
}

customElements.define("eop-google-tag-manager-tracking", EopGoogleTagManagerTracking);