import {AutoInitializing} from "../../common/elements";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {ManagingResources} from "../../common/lifetime";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import LEFT_ARROW from "../../../../resources/assets/images/left_arrow.svg";
import RIGHT_ARROW from "../../../../resources/assets/images/right_arrow.svg";
import {ScrollService} from "../../common/scroll";
import {resolve} from "../../container";
import {ResizeObserverFactory} from "../../common/observation";
import {HorizontalOverflow, HorizontalOverflowInstaller} from "./horizontalOverflow";
import {state} from "lit/decorators.js";

export const HORIZONTAL_SCROLL_ERROR_MARGIN = 8;

export const SCROLL_DURATION = 250;

export abstract class ScrollableBar extends ManagingResources(AutoInitializing(LitElement)) {

    protected abstract scrollableArea: HTMLElement;
    @state()
    private canScrollLeft: boolean;
    @state()
    private canScrollRight: boolean;
    private resizeObserver: ResizeObserver;
    private horizontalOverflow: HorizontalOverflow;

    protected constructor(
        protected scrollService: ScrollService = resolve(ScrollService),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.resizeObserver = this.resizeObserverFactory.create(() => this.toggleScrollButtonVisibility());
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(this.scrollableArea);
        this.resizeObserver.observe(this.scrollableArea);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow?.uninstall();
        this.resizeObserver.disconnect();
        super.disconnectedCallback();
    }

    protected renderScrollLeftButton(): TemplateResult | null {
        if (this.canScrollLeft) {
            return html`
                <button
                        class="scroll-button scroll-left"
                        @click=${this.scrollHorizontallyLeft}
                        data-tracking-label="scroll-left"
                >
                    ${unsafeSVG(LEFT_ARROW)}
                </button>`;
        } else {
            return null;
        }
    }

    protected renderScrollRightButton(): TemplateResult | null {
        if (this.canScrollRight) {
            return html`
                <button
                        class="scroll-button scroll-right"
                        @click=${this.scrollHorizontallyRight}
                        data-tracking-label="scroll-right"
                >
                    ${unsafeSVG(RIGHT_ARROW)}
                </button>`;
        } else {
            return null;
        }
    }

    private scrollHorizontallyLeft(): void {
        this.scrollHorizontallyBy(-this.scrollableArea.clientWidth);
    }

    private scrollHorizontallyRight(): void {
        this.scrollHorizontallyBy(this.scrollableArea.clientWidth);
    }

    private scrollHorizontallyBy(scrollX: number): void {
        this.scrollService.enclosingHorizontalArea(this.scrollableArea).scrollTo(scrollX + this.scrollableArea.scrollLeft, SCROLL_DURATION);
    }

    protected toggleScrollButtonVisibility(): void {
        const scrollLeft = this.scrollableArea.scrollLeft;
        const scrollWidth = this.scrollableArea.scrollWidth;
        const elementWidth = this.scrollableArea.clientWidth;

        if (elementWidth + HORIZONTAL_SCROLL_ERROR_MARGIN >= scrollWidth) {
            this.canScrollLeft = false;
            this.canScrollRight = false;
            return;
        }

        this.canScrollRight = scrollLeft + elementWidth + HORIZONTAL_SCROLL_ERROR_MARGIN < scrollWidth;
        this.canScrollLeft = scrollLeft > HORIZONTAL_SCROLL_ERROR_MARGIN;
    }
}