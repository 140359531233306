import {customElement, property, query} from "lit/decorators.js";
import {html, type PropertyValues, type TemplateResult} from "lit";
import {resolve} from "../../container";
import {VideoProgress, VideoTracking, VideoTrackingFactory} from "../../tracking/videoTracking";
import {UnLitElement} from "../../common/elements";

/* on Apple devices, preview image is shown only when thumbnail is provided. Therefore, we skip to first frame to show this as preview image.
Number must be very small to ensure progress bar is still on the very left
 */
const STARTING_TIME = "0.0000000001";

@customElement("eop-video")
export class EopVideo extends UnLitElement {

    @query("video")
    private videoElement: HTMLVideoElement;

    @property({attribute: "subtitle-src"})
    private subtitleSrc: string;
    @property({attribute: "thumbnail-src"})
    private thumbnailSrc: string;
    @property({attribute: "video-alt"})
    private alt: string;
    @property({attribute: "video-src"})
    private src: string;

    private tracking: VideoTracking;

    public constructor(private videoTrackingFactory: VideoTrackingFactory = resolve(VideoTrackingFactory)) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <video controls playsinline preload="metadata" poster=${this.thumbnailSrc} class="video-player">
                <source src=${this.videoSrc()}/>
                ${this.alt}
                ${this.renderSubtitle()}
            </video>
        `;
    }

    private videoSrc(): string {
        if (this.thumbnailSrc) {
            return this.src;
        } else {
            return this.src + "#t=" + STARTING_TIME;
        }
    }

    private renderSubtitle(): TemplateResult | null {
        if (this.subtitleSrc) {
            return html`
                <track default kind="captions" src=${this.subtitleSrc}>`;
        }
        return null;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.tracking = this.videoTrackingFactory.create(this.alt ?? "", new HtmlVideoProgress(this.videoElement));
        this.videoElement.addEventListener("play", () => {
            this.tracking.playing();
        });
        this.videoElement.addEventListener("pause", () => {
            this.tracking.pause();
        });
        this.videoElement.addEventListener("ended", () => {
            this.tracking.ended();
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this.tracking.disconnect();
    }
}

export class HtmlVideoProgress extends VideoProgress {
    public constructor(private videoElement: HTMLVideoElement) {
        super();
    }

    public currentTime = (): number => this.videoElement.currentTime;
    public duration = (): number => this.videoElement.duration;
}
