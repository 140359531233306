import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, queryAssignedElements} from "lit/decorators.js";
import Styles from "./macherbusCategory.lit.scss";
import {MACHERBUS_PROJECT_VOTE_EVENT, macherbusCategoryVoteEvent, type MacherbusProjectVoteEvent} from "./macherbusEvents";
import type {EopMacherbusProject} from "./macherbusProject";
import type {DirectiveResult} from "lit/directive.js";
import {templateContent} from "lit/directives/template-content.js";

@customElement("eop-macherbus-category")
export class EopMacherbusCategory extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "intro-id"})
    public introId: string;
    @property({attribute: "category-id"})
    private categoryId: string;
    @queryAssignedElements({slot: "macherbus-project"})
    private projects: Array<EopMacherbusProject>;

    private categoryIntro: DirectiveResult;

    public connectedCallback(): void {
        super.connectedCallback();

        const categoryIntroTemplate = this.resolveTemplateElement(this.introId);
        if (categoryIntroTemplate !== null) {
            this.categoryIntro = templateContent(categoryIntroTemplate);
        }

        this.addEventListener(MACHERBUS_PROJECT_VOTE_EVENT, (event: Event) => {
            const eventDetail = (event as MacherbusProjectVoteEvent).detail;
            this.projects.forEach(p => {
                p.acceptsVoteOut(eventDetail.projectId);
            });

            this.dispatchEvent(macherbusCategoryVoteEvent(this.categoryId, eventDetail.projectId));
        });
    }

    public render(): TemplateResult {
        return html`
            <div class="category-header">
                <div class="category-intro">
                    ${this.categoryIntro}
                </div>
            </div>
            <slot name="macherbus-project" class="macherbus-projects"></slot>
        `;
    }

    public getCategoryId(): string {
        return this.categoryId;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        const projects = Array.from(this.projects);
        projects.shuffle();
        this.append(...projects);
    }

    public voteFor(projectId: string): void {
        this.projects.forEach(p => p.acceptsVote(projectId));
    }
}