import {UnLitElement} from "../../../common/elements";
import {property, state} from "lit/decorators.js";
import {COLOR_SCHEME_CHANGED_EVENT, ColorScheme, type ColorSchemeData, ColorSchemeService} from "../../../common/colorSchemeService";
import {resolve} from "../../../container";
import {EventBus, EventBusEvent} from "../../../common/eventBus";
import {ManagingResources} from "../../../common/lifetime";

export abstract class SpecialImage extends ManagingResources(UnLitElement) {

    @property({attribute: "src"})
    protected src: string;
    @property({attribute: "src-dark"})
    protected srcDark: string;
    @property({attribute: "baseplate"})
    protected baseplate: string;
    @property({attribute: "out-of-view", type: Boolean})
    public outOfView: boolean = false;
    @state()
    protected srcCurrent: string;
    @state()
    protected enableBaseplate: boolean = false;

    protected constructor(
        protected colorSchemeService: ColorSchemeService = resolve(ColorSchemeService),
        protected eventBus: EventBus = resolve(EventBus)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        if (this.srcDark) {
            this.toggleSrc(this.colorSchemeService.getColorScheme());
            this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (event: EventBusEvent<ColorSchemeData>) => {
                this.toggleSrc(event.data.colorScheme);
            });
        } else {
            this.srcCurrent = this.src ?? "";
        }

        if (this.baseplate) {
            this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (event: EventBusEvent<ColorSchemeData>) => {
                this.toggleBaseplate(event.data.colorScheme);
            });

        }
        this.toggleBaseplate(this.colorSchemeService.getColorScheme());
    }

    protected toggleSrc(colorScheme: ColorScheme): void {
        this.srcCurrent = colorScheme === ColorScheme.LIGHT ? this.src ?? "" : this.srcDark ?? "";
    }

    protected toggleBaseplate(colorScheme: ColorScheme): void {
        this.enableBaseplate = !!this.baseplate && colorScheme === ColorScheme.DARK;
    }
}