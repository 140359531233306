import {AnimatedNumber, AnimatedNumberBuilder, AnimatedNumberFactory} from "../../page/elements/animatedNumber";
import {BufferingShareDataSource, type ShareData, type ShareDataObserver, type ShareDataSource} from "./shareDataSource";
import {resolve} from "../../container";
import {DATETIME_FORMATS, LanguagesService} from "../../common/languages";
import {Dictionary} from "../../page/elements/dictionary";

const MILLISECONDS_FACTOR = 1000;


export class EopShareData extends HTMLElement {


    public constructor(
        private shareDataSource: ShareDataSource = resolve(BufferingShareDataSource),
        private animatedNumberFactory: AnimatedNumberFactory = resolve(AnimatedNumberFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const dispatchDataObserver = (aspect: string): ShareDataObserver | null => {
            switch (aspect) {
                case "time":
                    return new ShareTime(this);
                case "value":
                    return new ShareValue(this.animatedNumberFactory.builder(), this);
                case "change":
                    return new ShareChange(this.animatedNumberFactory.builder(), this);
                case "changePercent":
                    return new ShareChangeInPercent(this.animatedNumberFactory.builder(), this);
                default:
                    return null;
            }
        };

        const dataObserver = dispatchDataObserver(this.getAttribute("aspect") as string);
        if (dataObserver) {
            this.shareDataSource.register(dataObserver);
        }
    }
}

class ShareTime implements ShareDataObserver {

    private dictionary: Dictionary;

    public constructor(
        private element: HTMLElement,
        private languagesService: LanguagesService = resolve(LanguagesService)
    ) {
        this.dictionary = Dictionary.of(element);
    }

    public receiveData(data: ShareData | null): void {
        if (!data) {
            this.element.textContent = "";
            return;
        }
        if (data.time === 0) {
            this.element.textContent = this.dictionary.translate("MSG_STOCK_EXCHANGE_CLOSED");
            return;
        }

        const langId = this.languagesService.activeLanguage().id;
        const date = new Date(data.time * MILLISECONDS_FACTOR).toLocaleString(langId, {
            ...DATETIME_FORMATS[langId],
            timeZoneName: "short",
            timeZone: "Europe/Berlin"
        });
        this.element.textContent = this.convertToCET(date);
    }

    private convertToCET(dateGMT: string): string {
        return dateGMT.replace("GMT+2", "CEST").replace("GMT+1", "CET");
    }
}

abstract class NumericShareData implements ShareDataObserver {
    private animatedNumber: AnimatedNumber;

    public constructor(animatedNumberBuilder: AnimatedNumberBuilder, element: HTMLElement) {
        this.animatedNumber = this.fineTuneAnimatedNumber(animatedNumberBuilder).appliedTo(element);
    }

    public receiveData(data: ShareData | null): void {
        const newValue = data ? this.extractNumber(data) : NaN;
        this.animatedNumber.update(newValue);
    }

    protected abstract fineTuneAnimatedNumber(animatedNumber: AnimatedNumberBuilder): AnimatedNumberBuilder;

    protected abstract extractNumber(data: ShareData): number;
}

class ShareValue extends NumericShareData {
    protected extractNumber(data: ShareData): number {
        return data.value;
    }

    protected fineTuneAnimatedNumber(animatedNumber: AnimatedNumberBuilder): AnimatedNumberBuilder {
        return animatedNumber.withFixedPrecision(2);
    }
}

class ShareChange extends NumericShareData {
    protected extractNumber(data: ShareData): number {
        return data.change;
    }

    protected fineTuneAnimatedNumber(animatedNumber: AnimatedNumberBuilder): AnimatedNumberBuilder {
        return animatedNumber
            .withFixedPrecision(2)
            .withNumberGroupSeparator(true)
            .withCustomFormatting((numberValue, stringValue) => (numberValue >= 0 ? "+" : "") + stringValue);
    }
}

class ShareChangeInPercent extends NumericShareData {
    protected extractNumber(data: ShareData): number {
        return data.changePercent;
    }

    protected fineTuneAnimatedNumber(animatedNumber: AnimatedNumberBuilder): AnimatedNumberBuilder {
        return animatedNumber
            .withCustomFormatting((numberValue, stringValue) => (numberValue >= 0 ? "+" : "") + stringValue);
    }
}

customElements.define("eop-share-data", EopShareData);