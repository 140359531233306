import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property, queryAssignedElements} from "lit/decorators.js";
import Styles from "./macherbusRankedCategory.lit.scss";
import type {EopMacherbusRankedProject} from "./macherbusRankedProject";
import {resolve} from "../../container";
import {MacherbusService, type Ranking} from "./macherbusService";
import type {DirectiveResult} from "lit/directive.js";
import {templateContent} from "lit/directives/template-content.js";

@customElement("eop-macherbus-ranked-category")
export class EopMacherbusRankedCategory extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "intro-id"})
    public introId: string;
    @property({attribute: "category-id"})
    private categoryId: string;
    @queryAssignedElements({slot: "macherbus-project"})
    private projects: Array<EopMacherbusRankedProject>;

    private categoryIntro: DirectiveResult;

    public constructor(private macherbusService: MacherbusService = resolve(MacherbusService)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        const categoryIntroTemplate = this.resolveTemplateElement(this.introId);
        if (categoryIntroTemplate !== null) {
            this.categoryIntro = templateContent(categoryIntroTemplate);
        }
    }

    public render(): TemplateResult {
        return html`
            <div class="category-header">
                <div class="category-intro">
                    ${this.categoryIntro}
                </div>
            </div>
            <slot name="macherbus-project" class="macherbus-projects"></slot>
        `;
    }

    public async rankProjects(votingId: string): Promise<void> {
        const rankedProjects = await this.macherbusService.getRanking(
            votingId,
            this.categoryId,
            this.projects.map(p => p.getProjectId()));

        this.setRanking(rankedProjects);
        this.sortProjects();
    }

    private setRanking(ranking: Ranking): void {
        ranking.rankedProjects.forEach(rankedProject => {
            this.projects.find(p => p.hasProjectId(rankedProject.projectId))
                ?.setRanking(rankedProject.rank, rankedProject.voteCount);
        });
    }

    private sortProjects(): void {
        const projects = [...this.projects].sort((a, b) => a.compareTo(b));
        this.append(...projects);
    }
}