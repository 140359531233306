import {GLOBAL} from "../../../common/globals";
import {Deferred, EOP_ERRORS} from "../../../common/utils/promises";
import {Load} from "../../../common/load";
import {autoRegister, resolve} from "../../../container";
import {Configuration} from "../../../common/config";
import {VideoProgress} from "../../../tracking/videoTracking";
import {EopYoutubePlayer} from "./youtubePlayer";
import {EopSocialYoutubeSeo, type VideoInformation} from "./socialYoutubeSeo";

export type YoutubeWindow = Window & {
    gapi: any;
    onYouTubeIframeAPIReady: () => void;
};

type RequestResponse = {
    items?: any[];
};

@autoRegister()
export class YoutubeLoader {
    private deferred: Deferred<void>;
    private loadingTriggered: boolean;

    private window: YoutubeWindow;

    public constructor(
        private configuration: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.deferred = new Deferred<void>();
        this.loadingTriggered = false;

        this.window = GLOBAL.window() as YoutubeWindow;
    }

    public run(): Promise<void> {
        if (this.loadingTriggered) {
            return this.deferred.promise;
        }

        const YOUTUBE_URL = this.configuration.get("YOUTUBE_URL");
        const YOUTUBE_KEY = this.configuration.get("YOUTUBE_KEY");

        if (YOUTUBE_URL && YOUTUBE_KEY) {
            this.loadingTriggered = true;
            this.load.script(YOUTUBE_URL)
                .then(() => {
                    this.window.gapi.load("client", () => {
                        this.window.gapi.client.init({
                            apiKey: YOUTUBE_KEY
                        });
                        this.window.gapi.client.load("youtube", "v3", () => {
                            this.deferred.resolve();
                        });
                    });
                }).catch(EOP_ERRORS);
        } else {
            this.deferred.reject("Missing configuration for Youtube API");
        }
        return this.deferred.promise;
    }
}

@autoRegister()
export class YoutubeIframeApiLoader {
    private deferred: Deferred<void>;
    private loaded: boolean;

    private window: YoutubeWindow;

    public constructor(
        private configuration: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.window = GLOBAL.window() as YoutubeWindow;

        this.deferred = new Deferred<void>();
        this.loaded = false;
    }

    public run(): Promise<void> {
        if (this.loaded) {
            return this.deferred.promise;
        }

        const YOUTUBE_IFRAME_API_URL = this.configuration.get("YOUTUBE_IFRAME_API_URL");

        if (YOUTUBE_IFRAME_API_URL) {
            this.window.onYouTubeIframeAPIReady = () => {
                this.loaded = true;
                this.deferred.resolve();
            };
            this.load.script(YOUTUBE_IFRAME_API_URL).catch(EOP_ERRORS);
        } else {
            this.deferred.reject("Missing configuration for Youtube Iframe API");
        }

        return this.deferred.promise;
    }
}

@autoRegister()
export class YoutubeService {
    private readonly initPromise: Promise<void>;
    private youtubeWindow: YoutubeWindow;

    public constructor(
        private youtubeLoader: YoutubeLoader = resolve(YoutubeLoader)) {
        this.youtubeWindow = GLOBAL.window() as YoutubeWindow;
        this.initPromise = this.youtubeLoader.run();
    }

    public async getVideoInfo(videoId: string): Promise<VideoInformation> {
        try {
            await this.initPromise;
            const request: any = this.youtubeWindow.gapi.client.youtube.videos.list({
                id: videoId,
                part: "contentDetails, snippet"
            });
            const requestDeferred: Deferred<any> = new Deferred();
            request.execute((response?: RequestResponse) => {
                const videoInformation: VideoInformation = {};
                if (response?.items?.length === 1) {
                    const video: any = response.items[0];
                    videoInformation.duration = video.contentDetails?.duration;
                    videoInformation.title = video.snippet?.title;
                    videoInformation.description = video.snippet?.description;
                    videoInformation.uploadDate = video.snippet?.publishedAt;
                }
                requestDeferred.resolve(videoInformation);
            });

            return await requestDeferred.promise;
        } catch (error) {
            EOP_ERRORS(error);
            throw error;
        }
    }
}

export class YoutubeVideoProgress extends VideoProgress {

    public constructor(private player: YT.Player) {
        super();
    }

    public currentTime = (): number => {
        if (this.player.getCurrentTime) {
            return this.player.getCurrentTime();
        } else {
            return 0;
        }
    };

    public duration = (): number => {
        if (this.player.getDuration) {
            return this.player.getDuration();
        } else {
            return 0;
        }
    };
}


customElements.define("eop-youtube-player", EopYoutubePlayer);
customElements.define("eop-social-youtube-seo", EopSocialYoutubeSeo);