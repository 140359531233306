import {customElement, property} from "lit/decorators.js";
import {UnLitElement} from "../../../common/elements";
import {html, type TemplateResult} from "lit";
import {GLOBAL} from "../../../common/globals";
import {autoRegister, resolve} from "../../../container";
import {Dictionary} from "../dictionary";

export interface Share {
    isSupported: () => boolean;
    share: (data: ShareData) => Promise<void>;
}

@autoRegister()
export class NavigatorShare implements Share {
    public isSupported(): boolean {
        return GLOBAL.navigator().share !== undefined;
    }

    public share(data: ShareData): Promise<void> {
        return GLOBAL.navigator().share(data);
    }
}

@customElement("eop-share-link")
export class EopShareLink extends UnLitElement {

    @property({attribute: "description"})
    private description: string;
    private dictionary: Dictionary;
    private shareButtonLabelKey = "MSG_SHARE_BUTTON";

    public constructor(private navigatorShare: Share = resolve(NavigatorShare)) {
        super();
    }

    public render(): TemplateResult | null {
        if (this.navigatorShare.isSupported()) {
            return html` | <span class="link share-link"
                                 @click=${this.shareLink}
                                 @keydown=${this.shareLink}
                                 tabindex="0"
                                 data-tracking-label="share-page"
            >${this.dictionary.translate(this.shareButtonLabelKey)}</span>`;
        }
        return null;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.dictionary = Dictionary.of(this);
    }

    private shareLink(): void {
        this.navigatorShare.share({
            title: document.title,
            text: this.description,
            url: new URL(GLOBAL.window().location.href).toString()
        }).catch(e => {
            if (e.toString().includes("AbortError")) {
                // noop. Can happen if user cancels the share dialog and should not lead to crash
            } else {
                console.error("Error link-sharing", e);
            }
        });
    }
}