import type {ElementViewTrackingData, InputTrackingData, InteractionTrackingData, VideoEventTrackingData, ViewTrackingData} from "./trackingData";
import type {TrackingListener} from "./listener";
import {autoRegister, resolve} from "../container";
import {Timeout} from "../common/timeout";
import {Configuration} from "../common/config";
import {GLOBAL} from "../common/globals";
import {ColorSchemeService} from "../common/colorSchemeService";
import type {GoogleTagManagerWindow} from "./googletagmanager/googleTagManager";
import {LanguagesService} from "../common/languages";

export function makeId(str?: string): string {
    if (str === "" || str === "/") {
        return ".homepage";
    }
    if (!str) {
        return ".null";
    }
    if (/^\/index$/.exec(str)) {
        return ".homepage";
    }
    if (/^\/index_en$/.exec(str)) {
        return ".homepage_en";
    }
    return str.toLowerCase()
        .replace(/\/index$/, "")
        .replace(/\/index_en$/, "")
        .replace(/\/$/, "")
        .replace(/ä/g, "ae")
        .replace(/ö/g, "oe")
        .replace(/ü/g, "ue")
        .replace(/ß/g, "ss")
        .replace(/\//g, ".")
        .replace(/[^0-9a-zA-Z.:?#\[\]@$&'()*+,°;={}\-_~<>|!§%^\\]+/g, "_");
}

@autoRegister()
export class Tracking {

    private listeners: TrackingListener[] = [];
    private manualPageTracking: boolean = false;

    public registerUnnamedListener(listener: TrackingListener): void {
        this.listeners.push(listener);
    }

    public manualTracking(): void {
        this.manualPageTracking = true;
    }

    public view(data?: ViewTrackingData): void {
        this.listeners.forEach(listener => listener.view(data ?? {}));
    }

    public initialView(): void {
        if (this.manualPageTracking) {
            return;
        }

        this.listeners.forEach(listener => listener.initialView());
    }

    public manualInitialView(): void {
        this.listeners.forEach(listener => listener.initialView());
    }

    public interaction(data: InteractionTrackingData): void {
        this.listeners.forEach(listener => listener.interaction(data));
    }

    public elementView(data?: ElementViewTrackingData): void {
        this.listeners.forEach(listener => listener.elementView(data ?? {}));
    }

    public errorView(data?: ViewTrackingData): void {
        this.listeners.forEach(listener => listener.errorView(data ?? {}));
    }

    public input(data?: InputTrackingData): void {
        this.listeners.forEach(listener => listener.input(data ?? {}));
    }

    public videoEvent(data?: VideoEventTrackingData): void {
        this.listeners.forEach(listener => listener.videoEvent(data ?? {}));
    }
}

export class EopTracking extends HTMLElement {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private timeout: Timeout = resolve(Timeout),
        _pageObject: PageObject = resolve(PageObject)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.timeout.delay(() => this.tracking.initialView(), 500);
    }
}

customElements.define("eop-tracking", EopTracking);

const WEBSITE_CORPORATE_ZONE = "uk";

//initial object to be pushed to the dataLayer
@autoRegister()
export class PageObject {

    private window: GoogleTagManagerWindow;
    private GOOGLE_ANALYTICS_STAGE_FLAG: string | undefined;

    public constructor(
        configuration: Configuration = resolve(Configuration),
        colorSchemeService: ColorSchemeService = resolve(ColorSchemeService),
        languagesService: LanguagesService = resolve(LanguagesService)
    ) {
        this.GOOGLE_ANALYTICS_STAGE_FLAG = configuration.get("GOOGLE_ANALYTICS_STAGE_FLAG");
        this.window = GLOBAL.window() as GoogleTagManagerWindow;
        this.window.dataLayer = this.window.dataLayer ?? [];
        this.window.dataLayer.push({
            page: {
                zone: WEBSITE_CORPORATE_ZONE,
                environment: this.GOOGLE_ANALYTICS_STAGE_FLAG,
                color_mode: colorSchemeService.getColorScheme().toLowerCase(),
                page_language: languagesService.activeLanguageId()
            }
        });
    }
}