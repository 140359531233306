import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {SpecialImage} from "./specialImage";

@customElement("eop-static-svg")
export class EopStaticSvg extends SpecialImage {

    @property({attribute: "alt"})
    private alt: string;

    public render(): TemplateResult {
        return html`<img src=${this.outOfView ? "" : this.srcCurrent} alt=${this.alt} loading="lazy" class="svg-image" ?baseplate=${this.enableBaseplate}/>`;
    }
}