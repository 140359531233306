import {customElement, property} from "lit/decorators.js";
import {resolve} from "../../../container";
import {PageOverlayTrustPolicyProvider} from "./pageOverlay";
import {absoluteHrefFrom} from "../../../common/utils/url";
import {UnLitElement} from "../../../common/elements";
import {schedule} from "../../../common/utils/promises";
import {isInOverlayContext, type PageOverlayHandler, PageOverlayHandlerProvider} from "./pageOverlayHandler";

declare global {
    interface HTMLElementTagNameMap {
        "eop-page-overlay-link": EopPageOverlayLink;
    }
}

@customElement("eop-page-overlay-link")
export class EopPageOverlayLink extends UnLitElement {
    private handler: PageOverlayHandler;

    @property({attribute: "link-class", state: false})
    private linkClass: string;
    @property({attribute: "href"})
    private href: string;
    @property({attribute: "context-preserving", type: Boolean})
    private contextPreserving: boolean = false;

    public constructor(
        private handlerProvider: PageOverlayHandlerProvider = resolve(PageOverlayHandlerProvider),
        private pageOverlayTrustPolicyProvider: PageOverlayTrustPolicyProvider = resolve(PageOverlayTrustPolicyProvider)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        schedule(this.setupHandler()).as("page-overlay-link-init");

        if (this.href) {
            if (this.contextPreserving) {
                this.addEventListener("click", ev => this.directClickListenerContextPreserving(ev));
            } else {
                this.addEventListener("click", ev => this.directClickListener(ev));
            }
            this.setAttribute("tabindex", "0");
            this.style.cursor = "pointer";
        } else {
            const linkElement = this.querySelector<HTMLAnchorElement>(`.${this.linkClass}`);
            if (!linkElement) {
                return;
            }

            linkElement.addEventListener("click", ev => this.innerElementClickListener(ev));
        }
    }

    private async setupHandler(): Promise<void> {
        this.handler = await this.handlerProvider.getPageOverlayHandler();
    }

    private directClickListener(ev: Event): void {
        this.openInOverlay(this.href, ev);
    }

    private directClickListenerContextPreserving(ev: Event): void {
        if (isInOverlayContext()) {
            this.openInOverlay(this.href, ev);
        } else {
            this.openLinkWithoutOverlay();
        }
    }

    private openLinkWithoutOverlay(): void {
        window.open(this.href, "_self");
    }

    private innerElementClickListener(ev: Event): void {
        const clicked = Array.from(ev.composedPath())
            .filter(element => element instanceof HTMLElement)
            .map(element => element as HTMLElement)
            .findFirst(elt => elt.isClickable())!;

        const shouldOpenOverlay = clicked.classList.contains(this.linkClass);
        if (!shouldOpenOverlay) {
            return;
        }

        const clickedLinkHref = clicked.getAttribute("href");
        if (!clickedLinkHref) {
            return;
        }

        this.openInOverlay(clickedLinkHref, ev);
    }

    private openInOverlay(clickedLinkHref: string, ev: Event): void {
        const absoluteHref = absoluteHrefFrom(clickedLinkHref);
        if (!this.pageOverlayTrustPolicyProvider.get().isTrustedHref(absoluteHref)) {
            // external link
            this.openLinkWithoutOverlay();
            return;
        }

        ev.preventDefault();
        ev.stopPropagation();
        this.handler.openPageInsideOverlay(absoluteHref);
    }
}