import {customElement, property, state} from "lit/decorators.js";
import {PageScrollbar} from "../../../../common/pageScrollbar";
import {resolve} from "../../../../container";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./mobileHeaderMenu.lit.scss";
import {
    mobileNavigationClosedEvent,
    mobileNavigationOpenedEvent,
    NAVIGATION_DOWN_EVENT,
    NAVIGATION_UP_EVENT,
    type NavigationDownEvent
} from "./mobileHeaderEvents";
import {NavigationItem, NavigationRootItem, NavModel} from "../common/navModel";
import type {MetaLink} from "../common/navLinkTypes";
import {Tracking} from "../../../../tracking/tracking";
import {NAVIGATION_JUMP_INSIDE_PAGE_EVENT, type NavigationJumpInsidePageEvent} from "../common/headerEvents";
import type {EopNavigationSheet} from "./navigationSheet";
import {PageFeatures} from "../../../elements/pageFeatures";
import {elementFrom} from "../../../../common/utils/html";
import {ManagingResources} from "../../../../common/lifetime";
import {ScrollService} from "../../../../common/scroll";
import {GLOBAL} from "../../../../common/globals";

@customElement("eop-mobile-header-menu")
export class EopMobileHeaderMenu extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "with-language-switch", type: Boolean})
    public withLanguageSwitch: boolean = false;
    @property({attribute: "with-dark-mode-toggle", type: Boolean})
    public withDarkModeToggle: boolean = false;
    @property({reflect: true, type: Boolean})
    public open: boolean = false;
    @property({reflect: true, type: Boolean})
    public closedInstantly: boolean = false;
    @property({attribute: "sticky", type: Boolean})
    public sticky: boolean = false;
    @state()
    private navRoot: NavigationRootItem;
    @state()
    private metaLinks: MetaLink[] = [];
    @state()
    private currentSheetLevel: number | null = null;
    @state()
    private currentSubNavigationItems: Map<number, NavigationItem> = new Map<number, NavigationItem>;

    private backdrop: Element;

    public constructor(
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private scrollService: ScrollService = resolve(ScrollService),
        private tracking: Tracking = resolve(Tracking),
        private pageFeatures: PageFeatures = resolve(PageFeatures),
        private navModel: NavModel = resolve(NavModel)
    ) {
        super();
        this.metaLinks = this.navModel.getMetaLinks();
        this.navRoot = this.navModel.makeNavRoot();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.backdrop = this.pageFeatures.insert(elementFrom(`<div class="header-mobile-menu-backdrop"></div>`), this);

        this.addEventListener(NAVIGATION_DOWN_EVENT, (event: Event) => {
            const item = (event as NavigationDownEvent).detail;
            this.openLinkOnNextSheet(item);
        });

        this.addEventListener(NAVIGATION_UP_EVENT, () => {
            this.returnToPreviousSheet();
        });

        this.addEventListener(NAVIGATION_JUMP_INSIDE_PAGE_EVENT, (event: Event) => {
            const targetHref = (event as NavigationJumpInsidePageEvent).detail;
            this.navRoot.activateFor(this.navModel.getPathHierarchy(), targetHref);
            this.navRoot = NavigationRootItem.copyFrom(this.navRoot);
            this.closeMenuInstantly();
        });
    }

    public render(): TemplateResult {
        return html`
            <div class="mobile-header-menu-button-container" data-eventelement="mobile-menu-toggle"
                 @click=${this.toggleMenu}
                 data-tracking-label="mobile-menu-toggle"
            >
                <div class="hamburger-icon">
                    <div class="top-bun"></div>
                    <div class="patty"></div>
                    <div class="bottom-bun"></div>
                </div>
            </div>
            <div class="mobile-navigation-viewport">
                <div class="mobile-navigation-scroll-area">
                    <div class="mobile-navigation-sheet-container ${this.currentSheetLevel ? `level-${this.currentSheetLevel}-active` : ""}">
                        <eop-navigation-sheet
                                .navItem=${this.navRoot.getActiveSector()}
                                .sectorLinks=${this.navRoot.getSectors()}
                                .metaLinks=${this.metaLinks}
                                ?with-language-switch=${this.withLanguageSwitch}
                                ?with-dark-mode-toggle=${this.withDarkModeToggle}
                                ?active=${this.currentSheetLevel === 2}
                                .level=${2}>
                        </eop-navigation-sheet>
                        ${this.renderSubSheets()}
                    </div>
                </div>
            </div>
        `;
    }

    private renderSubSheets(): TemplateResult[] {
        const results: TemplateResult[] = [];
        this.currentSubNavigationItems.forEach((item, level) => results.push(html`
            <eop-navigation-sheet
                    .navItem=${item}
                    ?active=${this.currentSheetLevel === level}
                    .level=${level}>
            </eop-navigation-sheet>
        `));
        return results;
    }

    private openMenu(): void {
        this.open = true;
        this.closedInstantly = false;
        this.pageScrollbar.disablePageScrollability();
        this.currentSheetLevel = 2;
        this.backdrop.classList.add("visible");
        this.trackOpeningOfMobileMenu();
        this.dispatchEvent(mobileNavigationOpenedEvent());
    }

    private closeMenu(): void {
        this.open = false;
        this.pageScrollbar.enablePageScrollability();
        this.currentSheetLevel = null;
        this.backdrop.classList.remove("visible");
        this.dispatchEvent(mobileNavigationClosedEvent());
    }

    private toggleMenu(): void {
        if (this.open) {
            this.closeMenu();
            return;
        }
        if (this.sticky || this.headerIsFullyVisible()) {
            this.openMenu();
            return;
        }
        this.scrollService.scrollToTop(100).then(() => {
            this.openMenu();
        });
    }

    private headerIsFullyVisible(): boolean {
        return GLOBAL.htmlElement().getBoundingClientRect().top >= 0;
    }

    private closeMenuInstantly(): void {
        this.closedInstantly = true;
        this.closeMenu();
    }

    private trackOpeningOfMobileMenu(): void {
        this.tracking.elementView({
            viewedElement: {
                type: "mobileMenu",
                name: "mobile_nav"
            }
        });
    }

    private openLinkOnNextSheet(item: NavigationItem): void {
        this.activeSheet().scrollIntoView(true);
        this.currentSheetLevel = this.currentSheetLevel! + 1;
        this.currentSubNavigationItems.set(this.currentSheetLevel, item);
    }

    private returnToPreviousSheet(): void {
        this.currentSheetLevel = this.currentSheetLevel! - 1;
    }

    private activeSheet(): EopNavigationSheet {
        return this.renderRoot.querySelector("eop-navigation-sheet[active]")!;
    }
}